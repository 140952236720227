// Generated by Framer (937e5b9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/sas6VP7Vl";

const enabledGestures = {f3gHFVOqf: {hover: true}};

const cycleOrder = ["f3gHFVOqf"];

const variantClassNames = {f3gHFVOqf: "framer-v-1hs7sxv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.2, ease: [0, 0, 1, 1], type: "tween"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, CA7TntWKh: title ?? props.CA7TntWKh ?? "socials"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CA7TntWKh, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "f3gHFVOqf", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-kIuom", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"twitter.com"} openInNewTab><motion.a {...restProps} className={`${cx("framer-1hs7sxv", className)} framer-1i3sa9z`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"f3gHFVOqf"} ref={ref} style={{...style}} {...addPropertyOverrides({"f3gHFVOqf-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-hs8fcz"} data-styles-preset={"sas6VP7Vl"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>socials</motion.p></React.Fragment>} className={"framer-gs8fvu"} data-framer-name={"Link Big"} layoutDependency={layoutDependency} layoutId={"AWbkS9S0p"} style={{"--extracted-r6o4lv": "rgba(135, 135, 135, 0.99)", "--framer-paragraph-spacing": "0px"}} text={CA7TntWKh} transformTemplate={transformTemplate} variants={{"f3gHFVOqf-hover": {"--extracted-r6o4lv": "rgba(255, 255, 255, 0.99)"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-kIuom [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kIuom .framer-1i3sa9z { display: block; }", ".framer-kIuom .framer-1hs7sxv { height: 50px; overflow: hidden; position: relative; text-decoration: none; width: 144px; }", ".framer-kIuom .framer-gs8fvu { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-kIuom .framer-v-1hs7sxv .framer-1hs7sxv { cursor: pointer; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 144
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hdFl6XaTt":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CA7TntWKh":"title"}
 */
const FramerX94rYfKCI: React.ComponentType<Props> = withCSS(Component, css, "framer-kIuom") as typeof Component;
export default FramerX94rYfKCI;

FramerX94rYfKCI.displayName = "Footer / Footer Text";

FramerX94rYfKCI.defaultProps = {height: 50, width: 144};

addPropertyControls(FramerX94rYfKCI, {CA7TntWKh: {defaultValue: "socials", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerX94rYfKCI, [...sharedStyle.fonts])